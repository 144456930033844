<template>
    <div id="topBar" class="top-bar">
        <CustomerService inline class="top-bar__section">
            <template v-slot:prepend>
                <IconSvg filepath="phone.svg" class="top-bar__icon phone-svg" />
            </template>
        </CustomerService>

        <div class="top-bar__section">
            <IconSvg filepath="tick.svg" class="top-bar__icon tick-svg" />
            <translate tag="span" path="Header.satisfiedCustomers" class="text-uppercase">
                <strong slot="amount">{{ $t('Header.satisfiedCustomersAmount') }}</strong>
            </translate>
        </div>

        <div class="top-bar__section d-md-none d-xl-flex">
            <IconSvg filepath="tick.svg" class="top-bar__icon tick-svg" />
            <translate tag="span" path="Header.yearsExperience" class="text-uppercase">
                <strong slot="years">{{ $t('Header.yearsExperienceAmount') }}</strong>
            </translate>
        </div>

        <div class="top-bar__section">
            <IconSvg filepath="tick.svg" class="top-bar__icon tick-svg" />
            <translate tag="span" path="Header.upToCheaper" class="text-uppercase">
                <strong slot="percent">{{ $t('Header.upToCheaperAmount') }}</strong>
            </translate>
        </div>
    </div>
</template>

<script>
import CustomerService from '@/components/header/CustomerService';

export default {
    name: 'TopBar',
    components: {
        CustomerService
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/theming' as *;

.top-bar {
    background-color: var(--topbar-bg);
    color: hsl(0, 0%, 100%);
    height: variables.$topbar-desktop-height;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__section {
        flex: 1 1 auto;
        align-self: stretch;
        line-height: variables.$topbar-desktop-height;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &.customer-service {
            @include themify(schlemmerauszeit, edeka, panatlantic, douglas) {
                background-color: transparent;
            }
            background-color: var(--body-color);
            color: hsl(0, 0%, 100%);
            margin-right: -1px;
            z-index: 1;

            span.working-hours {
                font-style: italic;
                color: var(--topbar-workinghours-color);
                margin-left: 5px;
                //empty block jumps prevention
                min-height: 1em;
                min-width: 75px;
                display: inline-block;
            }
        }

        &:not(.customer-service):before {
            content: '';
            position: absolute;
            width: 1px;
            height: 20px;
            left: 0;
            top: 50%;
            margin-top: -10px;
            border-left: 1px solid hsl(0, 0%, 100%);
            opacity: 0.5;
            z-index: 2;
        }
    }

    &__icon {
        fill: var(--primary);
        height: 15px;
        width: 16px;
        margin-right: 5px;
    }
}
</style>
