<template>
    <div class="trustami">
        <div class="d-flex align-items-center justify-content-between">
            <div class="widget_container_badge"></div>
            <a
                href="https://www.trustami.com/erfahrung/59f32dcc53a499655f8b4568-bewertung"
                class="custom-content"
                target="_blank"
                rel="nofollow noopener"
            >
                <span class="stars">
                    <IconSvg v-for="star in maxRating" :key="star" filepath="star.svg" class="trustami-star" />
                    <!--<span class="actual-stars">
                        <IconSvg v-for="starActual in Math.floor(actualRating)" :key="starActual"
                                  filepath="star.svg" class="trustami-star-active"/>
                        <span class="last-star-wrapper" :style="lastClip">
                             <IconSvg filepath="star.svg" class="trustami-star-active"></IconSvg>
                        </span>
                    </span>-->
                </span>
                <p>Ausgezeichnet</p>
                <p>{{ actualRating.toFixed(2) }} / {{ maxRating.toFixed(2) }}</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Trustami',
    data() {
        return {
            userId: process.env.VUE_APP_TRUSTAMI_PROFILE,
            profileId: process.env.VUE_APP_TRUSTAMI_PROFILE,
            maxRating: 5.0,
            actualRating: 5.0
        };
    },
    props: {},
    computed: {
        lastClip() {
            const ratingDecimal = (this.actualRating % 1).toFixed(2);
            return `width:${20 * +ratingDecimal}px`;
        }
    },
    created() {
        if (process.browser) {
            let trustamiScript = document.createElement('script');
            trustamiScript.setAttribute('src', 'https://cdn.trustami.com/widgetapi/widget2/trustami-widget.js');
            trustamiScript.dataset.profile = '5658150b2b5d85a0778b4725';
            trustamiScript.dataset.user = '6d7467ad417694162bcee3a43d7cd2e2c70f438a';
            trustamiScript.dataset.platform = 0;
            trustamiScript.setAttribute('defer', '');
            document.body.appendChild(trustamiScript);
        }
    }
};
</script>

<style scoped lang="scss">
.trustami {
    .widget_container_badge {
        height: 40px;
    }

    .custom-content {
        color: var(--header-color);
        font-size: 10px;
        text-decoration: none;
        text-align: center;

        .stars {
            vertical-align: middle;

            .icon-star {
                margin: 0 1px;
            }
        }

        p,
        .stars {
            line-height: 1.2em;
            margin: 0;
        }
    }
}
</style>
