<template>
    <div id="animod" :class="[priceModeCSS]" ref="app" v-show="resourcesLoaded">
        <!-- !Important. Inner vue logic sometimes breaks the whole website just because
             it attempts to process a comment node as an element
             ("Cannot read property 'toLowerCase' of undefined") and
             ("el.setAttribute is not a function") errors.
             The commend node is constructed as an empty placeholder for the falsy v-if.
             In the ssr combined layout server assumes that isMobile is true (by the user agent string)
             but client side can correctly evaluate isMobile as false.
             To prevent server from rendering a comment node for !$root.isMobile,
             I added an element node for the v-else condition
          -->
        <media-only desktop>
            <Navigation v-if="showNavigation" />
        </media-only>

        <main id="main">
            <nuxt />
        </main>

        <PriceModeToggle :is-checkout-route="isCheckoutRoute" />

        <client-only v-if="!$root.isMobile">
            <ToTopButton />
        </client-only>
    </div>
</template>

<script>
import CookiesNotice from '@/components/CookiesNotice';
import authorizedLayout from '@/mixins/authorizedLayout';
import partnerDOMLayout from '@/mixins/partnerDOMLayout';
import priceModeMixin from '@/mixins/priceModesGlobal';
import translatableLayout from '@/mixins/translatableLayout';
import ToTopButton from '@/components/ToTopButton';
import Navigation from '@/components/navigation/Navigation';
import Header from '@/components/header/Header';
import themeableLayout from '@/mixins/themeableLayout';
import PriceModeToggle from '@/components/PriceModeToggle';

export default {
    name: 'UrlaubsguruCombinedLayout',
    mixins: [priceModeMixin, authorizedLayout, partnerDOMLayout, translatableLayout, themeableLayout],
    components: {
        PriceModeToggle,
        CookiesNotice,
        ToTopButton,
        Navigation,
        Header
    },
    data() {
        return {
            ugTemplate: null,
            resourcesLoaded: false
        };
    },
    methods: {
        async getUGTemplate() {
            const { data: ugTemplate } = await this.axios.get('https://hotelgutscheine.urlaubsguru.de/animod.html');
            this.ugTemplate = ugTemplate;
        }
    },
    async mounted() {
        await this.getUGTemplate();
        let domParser = new DOMParser();
        let doc = domParser.parseFromString(this.ugTemplate, 'text/html');

        //Naked markup flickering prevention: hiding everything until all stylesheets loaded
        document.body.style.visibility = 'hidden';

        //Extracting stylesheets from all head elements. Appending filtered stylesheets
        const styles = this.extractStyleElements(doc.head.children);
        const allStylesLoaded = this.appendAndWaitStyleElements(styles, true);

        //Extracting scripts from the ug document
        const scriptsHead = this.extractScriptElements(doc.head.children, true);
        const scriptsBody = this.extractScriptElements(doc.body.children, true);

        document.head.insertAdjacentHTML('beforeend', doc.head.innerHTML);
        document.body.insertAdjacentHTML('afterbegin', doc.body.innerHTML);

        this.resourcesLoaded = true;

        //Appending scripts after html insertion
        await this.appendAndExecute(scriptsHead, document.head);
        await this.appendAndExecute(scriptsBody, document.body);

        //Appending animod webshop into container
        const container = document.querySelector('.hg-container');
        container.parentElement.replaceWith(this.$refs.app);

        await allStylesLoaded;

        document.body.style.visibility = '';
    }
};
</script>
