const pendingScripts = new Map();
const pendingStyles = new Map();

/**
 * @function
 * @param {string} src
 */
const findScript = src => {
    return Array.from(document.scripts).find(s => s.src === src);
};

export const appendScript = (src, options = {}, target) => {
    if (pendingScripts.has(src)) {
        return pendingScripts.get(src);
    }

    const promise = new Promise((resolve, reject) => {
        if (findScript(src) && !pendingScripts.has(src)) {
            resolve();
            return;
        }

        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.src = src;

        Object.assign(script, options);

        script.addEventListener('load', () => {
            pendingScripts.delete(src);
            resolve();
        });
        script.addEventListener('error', () => {
            pendingScripts.delete(src);
            unloadScript(src);
            console.error(`Failed to load script: ${src}`);
            resolve(); // resolve instead of reject as returned Promise must be resolved for the consequent script execution
        });
        script.addEventListener('abort', () => {
            pendingScripts.delete(src);
            unloadScript(src);
            console.warn(`Aborted loading script: ${src}`);
            resolve(); // resolve instead of reject as returned Promise must be resolved for the consequent script execution
        });

        (target || document.body).appendChild(script);
    });

    pendingScripts.set(src, promise);

    return promise;
};

export const unloadScript = src => {
    if (process.client) {
        const script = document.querySelector(`script[src="${src}"]`);
        if (!script) {
            console.warn(`Didn't manage to find script for removal: ${src}`);
            return false;
        }

        document.body.removeChild(script);
        return true;
    }
};

export const appendStyle = (href, options) => {
    if (pendingStyles.has(href)) {
        return pendingStyles.get(href);
    }

    const promise = new Promise((resolve, reject) => {
        if (document.querySelector(`link[href="${href}"]`)) {
            resolve();

            return;
        }

        const style = document.createElement('link');

        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = href;

        Object.assign(style, options);

        style.addEventListener('load', () => {
            pendingStyles.delete(href);
            resolve();
        });
        style.addEventListener('error', () => {
            pendingStyles.delete(href);
            reject();
        });
        style.addEventListener('abort', () => {
            pendingStyles.delete(href);
            reject();
        });

        document.head.appendChild(style);
    });

    pendingStyles.set(href, promise);

    return promise;
};

export const unloadStyle = href => {
    return new Promise((resolve, reject) => {
        const style = document.querySelector(`link[href="${href}"]`);
        if (!style) {
            reject();

            return;
        }

        document.head.removeChild(style);
        resolve();
    });
};
