<template functional>
    <div class="header-badge" :class="[data.class, data.staticClass]">
        <p class="header">{{ props.header }}</p>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'HeaderBadge'
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.header-badge {
    color: var(--header-color);
    height: variables.$badgeSeparatorHeight;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 0 variables.$grid-padding;

    &:last-of-type {
        padding-right: 0;
    }

    .header {
        font-size: 12px;
        text-transform: uppercase;
        opacity: 0.7;
        margin-bottom: 2px;
        white-space: nowrap;
    }
}
</style>
