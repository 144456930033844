import axios from 'axios';
import { appendScript, appendStyle } from '@/utils/injectRemote';
import { de } from 'suneditor/src/lang';

/**
 * @link https://grrr.tech/posts/create-dom-node-from-html-string/
 * @link https://ghinda.net/article/script-tags/
 */

export default {
    computed: {
        /**
         * Headerless axios instance for making requests to the third-party endpoints
         * @returns {AxiosInstance}
         */
        axios() {
            //creating a separate instance without unnecessary headers
            const instance = axios.create();
            instance.defaults.headers.common = {};
            instance.defaults.auth = null;
            return instance;
        },
        platformLogoHref() {
            return this.$store.state.globalMeta.platformLogoHref;
        }
    },
    methods: {
        /**
         * Adds a third-party script tag to the document head. Client-side only
         * @param {String} src
         * @param {Object} [options]
         * @param {Node} target
         */
        addScript(src, options = {}, target) {
            if (process.client) {
                return appendScript(...arguments);
            }
        },
        /**
         * Adds a third-party stylesheet to the document head. Client-side only
         * @param {String} href,
         * @param {Object} [options]
         */
        addStyle(href, options = {}) {
            if (process.client) {
                return appendStyle(href, options);
            }
        },
        /**
         * Converts HTMLString to the DOM Node or the HTMLCollection . Client-side only
         * @param {String} html
         * @returns {Node|HTMLCollection}
         */
        htmlStringToNodes(html) {
            if (process.client) {
                const template = document.createElement('template');
                html = html.trim(); // Never return a text node of whitespace as the result
                template.innerHTML = html;
                if (template.content.childElementCount > 1) {
                    return template.content.children;
                }
                return template.content.firstChild;
            }
        },
        /**
         * Gets all ancestors of the HTMLElement. Client-side only
         * @param {HTMLElement} elem
         * @returns {[]}
         */
        getParents(elem) {
            if (process.client) {
                let parents = [];

                // Get matching parent elements
                for (; elem && elem !== document; elem = elem.parentNode) {
                    parents.push(elem);
                }
                return parents;
            }
        },
        /**
         * insertAdjacentHTML method doesn't execute appended scripts.
         * The following method uses createContextualFragment to run the appended scripts
         * @param {String|Array|HTMLCollection} scripts
         * @param {HTMLElement} target
         * @returns {Promise}
         */
        appendAndExecute(scripts, target) {
            if (process.client) {
                /**
                 * NOTE! createContextualFragment appends and executes scripts, but in the order of scripts async load
                 * Therefore converting to the HTMLCollection and sequentially appending
                 */
                const htmlCollection =
                    typeof scripts === 'string'
                        ? document.createRange().createContextualFragment(scripts).children
                        : scripts;

                const htmlCollectionFiltered = this.extractScriptElements(htmlCollection);

                this.delayJqueryReady(htmlCollectionFiltered);

                /**
                 * NOTE: setting script async=false should force the “as in the document” load order.
                 * The following async reducer implementation could have been omitted
                 * https://javascript.info/script-async-defer
                 */

                return Array.prototype.reduce.call(
                    htmlCollectionFiltered,
                    async (promise, script) => {
                        await promise;

                        if (script.src) {
                            return this.addScript(
                                script.src,
                                {
                                    type: script.type || 'text/javascript' // we need to support modules as well
                                },
                                target
                            );
                        } else {
                            const s = document.createElement('script');
                            s.type = 'text/javascript';
                            s.textContent = script.innerText;
                            (target || document.body).appendChild(s);
                            return Promise.resolve();
                        }
                    },
                    Promise.resolve()
                );

                /*const fragment = document.createRange().createContextualFragment(scriptsString);
                (target || document.body).appendChild(fragment);*/
            }
        },
        /**
         * Appends style elements and returns the list of loaded promises
         * @param {Array|HTMLCollection} stylesList
         * @param {Boolean} [shouldDetach]
         * @param {Boolean} [shouldOverride]
         * @returns {Promise}
         */
        appendAndWaitStyleElements(stylesList, shouldDetach, shouldOverride = true) {
            if (process.client) {
                if (this.$isDev) {
                    console.assert(
                        Array.isArray(stylesList) || stylesList instanceof HTMLCollection,
                        'Provide a list of styles to append and wait for'
                    );
                }

                const promises = [];
                const insertionMethod = shouldOverride ? 'append' : 'prepend';

                Array.prototype.forEach.call(stylesList, style => {
                    shouldDetach && style.remove();

                    promises.push(
                        new Promise((resolve, reject) => {
                            style.addEventListener('load', resolve);
                        })
                    );

                    document.head[insertionMethod](style);
                });

                return Promise.all(promises);
            }
        },
        fixResourcesUrl(htmlCollection, newDomain) {
            if (process.client) {
                const currentDomain = location.origin;
                Array.prototype.forEach.call(htmlCollection, resource => {
                    const attr = resource.hasAttribute('src') ? 'src' : resource.hasAttribute('href') ? 'href' : null;
                    if (attr) {
                        if (resource[attr].startsWith('/')) {
                            resource.setAttribute(attr, `${newDomain}${resource[attr]}`);
                        } else if (resource[attr].includes(currentDomain)) {
                            resource.setAttribute(attr, resource[attr].replace(currentDomain, newDomain));
                        }
                    }
                });
            }
        },
        /**
         * Extracts style tags and stylesheet links from the collection of elements
         * @param {Array|HTMLCollection} nodes
         * @param {Boolean} [shouldDetach]
         * @returns {HTMLLinkElement[] | HTMLStyleElement[]}
         */
        extractStyleElements(nodes, shouldDetach) {
            const filtered = Array.prototype.filter.call(nodes, node => {
                return (
                    node.nodeName.toLowerCase() === 'style' ||
                    (node.nodeName.toLowerCase() === 'link' && node.rel === 'stylesheet')
                );
            });
            shouldDetach && filtered.forEach(style => style.remove());
            return filtered;
        },
        /**
         * Extracts script tags from the collection of elements
         * @param nodes
         * @param {Boolean} [shouldDetach]
         * @returns {Array}
         */
        extractScriptElements(nodes, shouldDetach) {
            const filtered = Array.prototype.filter.call(nodes, node => {
                const type = node.getAttribute('type') || '';
                return node.nodeName.toLowerCase() === 'script' && !type.includes('application/');
            });
            shouldDetach && filtered.forEach(script => script.remove());
            return filtered;
        },
        /**
         * Postpones jQuery.ready handlers execution
         * @param {Array<HTMLElement>} scriptElements
         */
        delayJqueryReady(scriptElements) {
            const jQueryIndex = scriptElements.findIndex(script => {
                const scriptSrc = script.src.toLowerCase();
                return script.src && (scriptSrc.includes('jquery.js') || scriptSrc.includes('jquery.min.js'));
            });
            if (~jQueryIndex) {
                const readyFnFixScript = document.createElement('script');
                readyFnFixScript.type = 'text/javascript';
                readyFnFixScript.innerText = 'window.jQuery.holdReady && window.jQuery.holdReady( true );';
                scriptElements.splice(jQueryIndex + 1, 0, readyFnFixScript);
            }
        },
        /**
         * https://stackoverflow.com/a/49160760 - very informative on related CSSOM security issues
         * @param {HTMLLinkElement[] | HTMLStyleElement[]} styleOrLinkElements
         * @param {string} selector
         * @returns {void}
         */
        scopeStyleElements(styleOrLinkElements, selector) {
            const nestStyleRule = cssStyleRule => {
                const selectors = cssStyleRule.selectorText.split(',');

                cssStyleRule.selectorText = selectors
                    .map((ruleSelector, index) => {
                        const trimmedSelector = ruleSelector.trim();
                        if (trimmedSelector.startsWith('body') || trimmedSelector.startsWith('html')) {
                            // example "body .child => body ${selector} .child"
                            if (trimmedSelector.split(' ').length > 1) {
                                return `${trimmedSelector} ${selector}`;
                            }
                            // example "body" =>
                            else {
                                return '';
                            }

                            //return `:where(${trimmedSelector}) ${selector}`;
                        } else {
                            return `${selector} ${trimmedSelector} `;
                        }
                    })
                    .join(',');
            };

            const parser = new DOMParser();

            styleOrLinkElements.forEach(async styleOrLinkElement => {
                if (styleOrLinkElement.nodeName.toLowerCase() === 'link') {
                    // Important to get this with CORS to be able to read cssRules
                    styleOrLinkElement.setAttribute('crossorigin', 'anonymous');

                    //styleOrLinkElement.setAttribute('disabled', 'true');
                    styleOrLinkElement.addEventListener('load', e => {
                        const cssStyleSheet = e.target.sheet;

                        Array.prototype.forEach.call(cssStyleSheet.cssRules, cssRule => {
                            if (cssRule instanceof CSSGroupingRule) {
                                Array.prototype.forEach.call(cssRule.cssRules, cssRule => {
                                    if (cssRule instanceof CSSStyleRule) {
                                        nestStyleRule(cssRule);
                                    }
                                });
                            } else {
                                if (cssRule instanceof CSSStyleRule) {
                                    nestStyleRule(cssRule);
                                }
                            }
                        });
                    });
                } else if (styleOrLinkElement.nodeName.toLowerCase() === 'style') {
                    let targetStyleSheet = styleOrLinkElement;
                    const crossDomainBlocked = !styleOrLinkElement.sheet;

                    if (crossDomainBlocked) {
                        const parsedDocument = parser.parseFromString(styleOrLinkElement.outerHTML, 'text/html');
                        const parsedStyle = parsedDocument.querySelector('style');
                        if (!parsedStyle) {
                            console.warn('scopeStyleElements: Unable to parse third-party styles');
                            return;
                        } else {
                            targetStyleSheet = parsedStyle;
                        }
                    }

                    Array.prototype.forEach.call(targetStyleSheet.sheet.cssRules, cssRule => {
                        if (cssRule instanceof CSSGroupingRule) {
                            Array.prototype.forEach.call(cssRule.cssRules, cssRule => {
                                if (cssRule instanceof CSSStyleRule) {
                                    nestStyleRule(cssRule);
                                }
                            });
                        } else {
                            if (cssRule instanceof CSSStyleRule) {
                                nestStyleRule(cssRule);
                            }
                        }
                    });
                }
            });
        },

        setLogoHref(logoAnchorElement) {
            if (process.client && this.platformLogoHref && logoAnchorElement instanceof HTMLAnchorElement) {
                logoAnchorElement.setAttribute('href', this.platformLogoHref);
            }
        },

        dispatchDOMContentLoaded() {
            if (process.client) {
                window.document.dispatchEvent(
                    new Event('DOMContentLoaded', {
                        bubbles: true,
                        cancelable: true
                    })
                );
            }
        }
    }
};
