<template>
    <div v-if="showToggle" id="priceModeToggle" class="container text-right mt-3">
        <button @click="togglePriceMode" class="btn btn-primary">
            {{$t('Price.priceDisplay')}}:
            <span v-if="priceMode.property === priceModes.package.property">
                {{$t('Price.package')}}
            </span>
            <span v-else-if="priceMode.property === priceModes.perPerson.property">
                {{$t('Price.perPerson')}}
            </span>
            <span v-else-if="priceMode.property === priceModes.perNight.property">
                {{$t('Price.perPersonPerNight')}}
            </span>
        </button>
    </div>
</template>

<script>
    import priceModes from '@/models/priceModes';

    export default {
        name: 'PriceModeToggle',
        data() {
            return {
                priceModes
            };
        },
        props: {
            isCheckoutRoute: Boolean
        },
        computed: {
            showToggle() {
                // incompatible with some places in a main webshop: product details (price mode is ignored)
                return !this.isCheckoutRoute
                    && this.$store.state.theme !== "netto-discount"
                    && this.$store.state.globalMeta.multivoucherShop
                    && this.$store.state.globalMeta.surchargeEnabled;
            },
            priceMode() {
                return this.$store.state.priceMode;
            }
        },
        methods: {
            togglePriceMode() {
                const nextMode = this.priceModes.nextLoop(this.priceMode);
                this.$store.dispatch('togglePriceMode', nextMode);
            }
        }
    }
</script>

<style scoped lang="scss">
    #priceModeToggle {
        .btn { min-width: 266px; }
    }
</style>
